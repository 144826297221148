<template>
  <div v-loading="loaders.exportReport" class="m-0 px-3 pt-4 pb-0 pr-2">
    <export-report-modal
      @exportReport="exportNonConnectedPatients"
    >
    </export-report-modal>
    <div class="d-flex justify-content-between">
      <h1 class="m-0">Never Used App</h1>
      <base-button size="md" id="export"
                   class="float-right mr-4 mt-2 white-outline-button button-font title-btn-padding"
                   @click="showExportReportModal"
                   type="secondary">
        <img alt="Image placeholder" class="mt--1" src="/img/svg/export.svg">
        Export
      </base-button>
    </div>
    <div v-loading="loaders.response" class="mt-4 cardStyle">
      <div class="d-flex justify-content-center justify-content-sm-between flex-wrap my-3 ml-3">
        <div class="d-inline-block">
          <label class="form-control-label">Duration</label>
          <el-select
            class="w-100"
            v-model="request.api.duration"
            placeholder="Select Duration"
            @change="getUnconnectedPatients"
          >
            <el-option
              class="select-danger"
              :value="0"
              label="All"
              key="-1">
            </el-option>
            <el-option
              v-for="(duration, key) in durations"
              class="select-danger"
              :value="duration.value"
              :label="duration.label"
              :key="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
        :data="response.unconnectedPatients"
        row-key="id"
        class="table-responsive table-flush p-3"
        header-row-class-name="thead-light"
        @sort-change="sortMethod"
      >
        <el-table-column label="PATIENT NAME" min-width="140px">
          <template slot-scope="scope">
            <el-tooltip
              class="item" effect="dark"
              :content="hoverPatientInfo(scope.row)"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <router-link :to="{name: 'PatientDetail', params: {id: scope.row.id}}">
                <span class="color-maroon">{{ scope.row.first_name + ' ' + scope.row.last_name }}</span>
              </router-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="JOIN DATE" prop="program_join_date" min-width="140px" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.program_join_date | formatDate }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <seminar-surgery-footer :pagination="pagination" @changePage="changePage"
                                  @changedPageLength="changedPageLength"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import Helper from "@/util/globalHelpers";
import {Option, Select, Table, TableColumn} from "element-ui";
import moment from "moment-timezone";
import fileDownload from "@/mixins/fileDownload";
import ExportReportModal from "@/views/Pages/Seminar2Surgery/ExportReportModal";

export default {
  name: "NotConnectedPatients",
  components: {
    SeminarSurgeryFooter,
    ExportReportModal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loaders: {
        response: false,
        exportReport: false
      },
      request: {
        api: {
          duration: -1,
          sortBy: 'created',
          sortByDir: 'DESC'
        },
      },
      response: {
        unconnectedPatients: [],
      },
      pagination: {
        currentPage: 1,
        perPage: 20,
        page: 1,
        lastPage: 1,
      },
      durations: [
        {
          label: 'This Year',
          value: -1
        },
        {
          label: 'Last Year',
          value: -2
        },
        {
          label: '2 Years',
          value: -3
        },
        {
          label: '3 Years',
          value: -4
        },
        {
          label: '5 Years',
          value: -6
        },
        {
          label: '10 Years',
          value: -11
        },
      ],
    }
  },
  mounted() {
    this.getUnconnectedPatients()
  },
  mixins: [
    fileDownload
  ],
  filters: {
    /**
     * Return the formatted surgery date
     * @param date
     * @returns {string|string}
     */
    formatDate: function (date) {
      return date ? moment(date).format('MMM D, YYYY') : ''
    },
  },
  methods: {
    /**
     * Assign the Params and return it
     * @returns {{duration: number, perPage, sortBy: (string|*), sortByDir: (string|*), page}}
     */
    assignParams() {
      let vm = this
      return {
        duration: vm.request.api.duration,
        sortBy: vm.request.api.sortBy,
        sortByDir: vm.request.api.sortByDir,
        page: vm.pagination.page,
        perPage: vm.pagination.perPage
      }
    },

    /**
     * Show the Export Report Modal
     */
    showExportReportModal() {
      this.$store.commit('toggleExportReportModal', true)
    },

    /**
     * Hide the Export Report Modal
     */
    hideExportReportModal() {
      this.$store.commit('toggleExportReportModal', false)
    },

    /**
     * Save the API response object
     * @param response
     */
    saveApiResponse(response) {
      let vm = this
      vm.response.unconnectedPatients = []
      vm.response.unconnectedPatients = [...response.data.data.data]
      vm.pagination.currentPage = response.data.data.current_page
      vm.pagination.lastPage = response.data.data.last_page
      vm.pagination.perPage = response.data.data.per_page
      vm.pagination.page = response.data.data.current_page
      vm.pagination.total = response.data.data.total
    },

    /**
     * Get All the Non Connected Patients
     */
    getUnconnectedPatients() {
      let vm = this
      vm.loaders.response = true
      const params = vm.assignParams()
      vm.$store.dispatch('_notConnectedPatientsReport', params)
        .then(response => {
          vm.saveApiResponse(response)
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Not Connected Patients',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.response = false
        })
    },

    /**
     * Export the Non-Connected Patients report
     */
    exportNonConnectedPatients() {
      let vm = this
      vm.hideExportReportModal()
      vm.loaders.exportReport = true
      const config = {
        responseType: "blob",
        params: {...vm.request.api}
      }
      vm.$store.dispatch('_notConnectedPatientsExcel', config)
        .then(response => {
          vm.generateExportFileDownload(response, 'Baritastic-All-Non-Connected-Patients')
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Not Connected Patients Excel',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.exportReport = false
        })
    },

    /**
     * Sorting based on which prop is passed and fetch the post op patients
     * @param prop
     * @param order
     */
    sortMethod({prop, order}) {
      let vm = this
      if (order) {
        vm.request.api.sortBy = prop
        vm.request.api.sortByDir = order == 'ascending' ? 'ASC' : 'DESC'
      } else {
        vm.request.api.sortBy = "created"
        vm.request.api.sortByDir = 'DESC'
      }
      vm.pagination.page = 1
      vm.getUnconnectedPatients()
    },

    /**
     * Return the basic information about user
     * @param row
     * @returns {string}
     */
    hoverPatientInfo(row) {
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true && row.first_name !== '' && row.first_name != null) ? returnable + row.first_name : returnable + ''
      returnable = (row.hasOwnProperty('last_name') === true && row.last_name !== '' && row.last_name != null) ? returnable + ' ' + row.last_name : returnable + ''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable + ', ' + row.email : returnable + ''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable + ', ' + row.phone : returnable + ''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null) {
        if (!isNaN(Date.parse(row.dob))) {
          const date = new Date(row.dob)
          returnable = returnable + ', ' + Helper.extractAndFormatDate(date.toDateString(), false)
        }
      } else {
        returnable = returnable + ''
      }
      returnable = (row.hasOwnProperty('mrn_number') === true && row.mrn_number !== '' && row.mrn_number != null) ? returnable + ', ' + row.mrn_number : returnable + ' '
      return returnable
    },

    /**
     * Change page in the listing
     * @param page
     */
    changePage(page) {
      if (this.pagination.page != page) {
        this.pagination.page = page
        this.getUnconnectedPatients()
      }
    },

    /**
     * Change page length in the listing
     * @param pageLength
     */
    changedPageLength(pageLength) {
      this.pagination.page = 1
      this.pagination.perPage = pageLength
      this.getUnconnectedPatients()
    },
  }
}
</script>

<style scoped>

</style>
